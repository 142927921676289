/**
 * General scripts
 *
 * This file should contain any scripts needed. Instead of calling it in the header
 * or throwing it inside wp_head(), this file will be called automatically in the
 * footer for better performance.
 *
 * @package	Seedy Joints
 * @since	8/4/14
 * @author	Chatman Design / Corey Caswick
 * @link	http://chatmandesign.com
 */

(function($) {
	//# WHILE LOADING

	// toggles stateClass on emailField based on validity of emailField
	// if submitButton is defined, conditionally enable/disable it.
	// TODO: this could probably use a better name.
	function update_alert( emailField, stateClass, submitButton ) {
		if ( emailField[0].checkValidity() ) {
			emailField.removeClass( stateClass );
			if ( submitButton !== undefined ) { submitButton.prop('disabled', false); }
		} else {
			emailField.addClass( stateClass );
			if ( submitButton !== undefined ) { submitButton.prop('disabled', true); }
		}
	}

	// Responsiveness
	var breakpoints = {};
	breakpoints.small  = window.matchMedia( "(max-width: 640px)" );
	breakpoints.medium = window.matchMedia( "(min-width: 641px) and (max-width: 1024px)" );
	breakpoints.large  = window.matchMedia( "(min-width: 1025px) and (max-width: 1440px)" );
	breakpoints.xlarge = window.matchMedia( "(min-width: 1441px)" );

	/***/
	//# AFTER DOM LOAD */
	/***/

	$(function() {

		//# – POLYFILLS & FALLBACKS */

		/**
		 * Makes "skip to content" link work correctly in IE9 and Chrome for better
		 * accessibility. From Twenty Fourteen.
		 *
		 * @link http://www.nczonline.net/blog/2013/01/15/fixing-skip-to-content-links/
		 */
		$(window).on( 'hashchange', function() {
			var element = document.getElementById( location.hash.substring( 1 ) );

			if ( element ) {
				if ( ! /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) {
					element.tabIndex = -1;
				}

				element.focus();

				// Repositions the window on jump-to-anchor to account for header height.
				window.scrollBy( 0, -80 );
			}
		} );

		//# – RESPONSIVE FEATURES */

		if ( !breakpoints.small.matches ) {
			// Load gravatars
			$('.comment img[data-gravatar]').each(function(){
				$(this).attr('src',$(this).attr('data-gravatar'));
			});
		} // not small

		// Slideout search bar
		//$('.search-slideout .search-form').cdSearchSlideout();
		fullSizeSearch();

		// Load up Foundation
		$(document).foundation({
		    equalizer: { // Settings for the equalizer plugin, used for the product cards on the store category pages
		        equalize_on_stack: true,
		    }
		});

		// Details/summary initiate polyfill
		if ($.fn.details) {
			$('details').details();
		}

		//# – PRINT OPTIMIZATIONS

		$('select').each(function(i){
			var $el = $(this);
			var $options = $el.find('option');

			$el.after('<ul id="print-select-' + i + '" class="print-select"></ul>');

			var $curPrintBox = $('#print-select-' + i);

			$options.each(function() {
				$curPrintBox.append("<li>" + $(this).text() + "</li>");
			});
		});

		//# – FOOTER REVEAL CHECKBOXES

		var footerForm = $(".footer-bar #mc-embedded-subscribe-form");
		// skip all this unless the footer form is present.
		if ( footerForm.length ) {
			var footerField = $(".footer-bar #mc-embedded-subscribe-form #mce-EMAIL");
			footerField.focus( function() {
				$(".footer-bar").addClass("active");
			});

			//# –– FOOTER VERIFY EMAIL
			footerField.blur( function() {
				// do this on blur
				update_alert( footerField, "mce_inline_error" );
				// make sure we only add this on() event once!
				var ev = $._data( footerField.get(0), 'events' );
				if ( ev && !ev.change ) {
					// do this on keyup and change
					footerField.on( "keyup change", function() {
						update_alert( footerField, "mce_inline_error" );
					});
				}
			});
			//# –– FACEBOOK PIXEL STANDARD EVENT: LEAD
			$(".footer-bar form#mc-embedded-subscribe-form").submit( function() {
				// do this when you click subscribe in the footer
				fbq('track', 'Lead');
				return true;
			});
		} // if footerForm is present

		//# – SEARCH RESULTS SIDEBAR SECTION EXPAND
		$(".search-results-sidebar").each( function () {
			var $sidebarSection = $(this);
			$sidebarSection.find('.search-results-sidebar__view-additional-results').click( function () {
				$(this).toggleClass('expanded');
				$sidebarSection.find('.search-results-sidebar__item--additional').toggleClass('hide');
			});
		});

		//# - PRETTY PHOTO
		$(document).ready(function(){
			$("a[data-rel^='prettyPhoto']").prettyPhoto({
				hook: 'data-rel',
				slideshow: false,
				social_tools: false,
				show_title: false,
				deeplinking: false,
				overlay_gallery: false
			});
		});

		//# - Content Block Smooth Scrolling
		$('div#hover-to').each(function(index, element) {
			$(element).on('click', function(event) {
				let hoverName = $(this).attr('hover-to');
				let hoverElem = $('div[hover-name="' + hoverName + '"]');
				if ( hoverElem.length ) {
					event.preventDefault();
					let scrollDistance = hoverElem.offset().top;
					$('html, body').stop().animate({
						scrollTop: scrollDistance
					}, 500);
				}
			});
		});

		//# - Video Drawer
		const cdVideoDrawer = '.cd-video-drawer';
		let $cdVideoDrawer = $(cdVideoDrawer);
		$cdVideoDrawer.each(function() {
			let $content = $(this).find(`${cdVideoDrawer}__content`);
			let $button = $(this).find(`${cdVideoDrawer}__button`);
			$button.on('click', function(event) {
				event.preventDefault();
				if( $content.hasClass('is-open') ) {
					$content.slideUp();
					$content.removeClass('is-open');
				} else {
					$content.slideDown();
					$content.addClass('is-open');
				}
			});
		});

		//# - Hide header when scrolling over this container
		let $hideContainer = $('.cd-scroll-header-hide');
		function hideHeaderWufoo() {
			let currentTop          = $(document).scrollTop();
			let containerHeight     = $hideContainer.outerHeight();
			let containerTop        = $hideContainer.offset().top - 16; // Hide 16px before container
			let mainNavHeight       = $('#main-nav').outerHeight();
			let $mainNav            = $('#main-nav');
			currentTop              = $(document).scrollTop();
			let isPast              = currentTop > containerHeight + containerTop;
			let inView              = currentTop + mainNavHeight >= containerTop;
			if( inView && !isPast ) {
				$mainNav.css('transform', 'translateY(-100%)');
			} else {
				$mainNav.css('transform', 'translateY(0%)');
			}
		}
		if( $hideContainer.length ) {
			hideHeaderWufoo();
			$(window).on('scroll', function(event) {
				hideHeaderWufoo();
			});
		}

		beaconHomeVideo();
		beaconBestOfSeven();
		desktopSlideMenu();
		beaconSliderOverlayAnchor();	
		beaconCategoryManipulate();
		beaconProductCalculation();
		beaconRequestInfoModal();
		beaconProductDataTabs();
		beaconBackButton();
		beaconBlockSlider();
	}); // jQuery document ready


	//# AFTER ALL ASSETS LOAD */

	$(window).load(function(){

		//# – Main Nav Mega-menus

		function openMegamenu(targetMenu, pushState) {
			$('.main-nav__menu-panel').hide();
			$('body').attr('data-menu-open', targetMenu);
			$('body').attr('data-scroll-position', $(window).scrollTop());
			$(targetMenu).show();
			if (pushState === true) history.pushState('', document.title, window.location.pathname + window.location.search + targetMenu);
		}
		function closeMegamenu(pushState) {
			$(window).scrollTop( $('body').attr('data-scroll-position') );
			$('body').removeAttr('data-menu-open');
			$('body').removeAttr('data-scroll-position');
			$('.main-nav__menu-panel').hide();
			if (pushState === true) history.pushState('', document.title, window.location.pathname + window.location.search);
		}

		function checkMegamenuHash() {
			if ( window.location.hash.substring(0, 9) == '#main-nav') {
				openMegamenu(window.location.hash, false);
			} else {
				closeMegamenu(false);
			}
		}

		function maybeInitNavMainProductsSlider(event, menu) {
			return; // No more slider
			if (menu == '#main-nav__products') {
				setTimeout(function(){

					// Do not run in outdated browsers
					if (typeof MutationObserver !== 'undefined') {
						var $tabbed = $('#nav-main-products-tabbed-content');
						var $tabs = $('#nav-main-products-tabs > .slider-tab');

						function updateTabs(currentSlide, nextSlide) {
							$tabs.filter('[data-goto-tab=' + currentSlide + ']').removeClass('is-current');
							$tabs.filter('[data-goto-tab=' + nextSlide + ']').addClass('is-current');
							$tabs.parent().attr('data-current-tab', nextSlide);
						}

						$tabbed.slick({
							'arrows': false,
							'infinite': false,
							'swipe': false,
							'speed': 500, // CSS transition for .tab-indicator must match
							'onBeforeChange': function(event, currentIndex, targetIndex) {
								updateTabs(currentIndex, targetIndex);
							}
						});

						$tabs.on('click', function (e) {
							e.preventDefault();
							var gotoTab = $(this).data('goto-tab');
							$tabbed.slickGoTo(gotoTab);
						});
					} // if not outdated

					$('body').off('menu-open', maybeInitNavMainProductsSlider);

				}, 0);
			} // if products menu

		} // maybeInitNavMainProductsSlider()

		//# –– UI
		var $mainNav = $('.main-nav');

		if ( breakpoints.large.matches || breakpoints.xlarge.matches ) {
			// Show overbar on hover
			$mainNav.hoverIntent({
				over: function (e) {
					$(this).addClass('has-hover-intent');
				},
				out: function (e) {
					$(this).removeClass('has-hover-intent');
				},
			});

			// Make sticky
			let overBarHeight = $('.main-nav__over-bar').height();
			$('.main-nav').sticky({
				topSpacing: 5 - overBarHeight,
			});
		} // if breakpoints...

		$mainNav.on('click', '[data-toggle-menu]', function(e) {
			e.preventDefault();
			
			// This needs to target only large screens
			
			let currentMenu = $('body').attr('data-menu-open');
			let targetMenu = $(this).attr('data-toggle-menu');

			if ( currentMenu == targetMenu ) {
				closeMegamenu(true);
			} else {
				openMegamenu(targetMenu, true);
			}

			setTimeout(function(){
				this.blur(); // Prevent lingering highlighted state after toggling menu
			}.bind(this), 20);
		});
		$('.main-nav__menu-panel').on('click', '[data-close-menu]', function(e) {
			e.preventDefault();
			closeMegamenu(true);
			$('.main-nav').removeClass('is-showing-menu-bar');
		});
		$('#main-nav__modal-bg').on('click', function(e) {
			closeMegamenu(true);
		});

		//# –– Toggle expand/collapse for "dropdowns"
		var $mainNavMenus = $('.main-nav__menu-panel .menu');

		var mainNavExpandableSelector = (breakpoints.small.matches || breakpoints.medium.matches)
			? '.has-dropdown' : '.dropdown .has-dropdown';

		var $mainNavExpandables = $mainNavMenus.find(mainNavExpandableSelector);
		$mainNavExpandables.on('click', '> a', function (e) {
			e.preventDefault();
			$(this).parent().toggleClass('is-dropdown-open');
		});

		// Emit events when menus open/close for modern browsers
		if (typeof MutationObserver !== 'undefined') {
			var menuObserver = new MutationObserver(function(mutations){
				mutations.forEach(function(m) {
					if (m.attributeName == 'data-menu-open') {
						if ( $('body').attr('data-menu-open') !== undefined ) {
							$('body').trigger( 'menu-open', $('body').attr('data-menu-open') );
						} else {
							$('body').trigger( 'menu-close', m.oldValue );
						}
					}
				});
			});
			menuObserver.observe(document.body, {
				attributes: true,
				childList: false,
				characterData: false,
			});
		} else {
			$('html').addClass('outdated-browser');
		} // if/else MutationObserver

		//# –– Init categories/brands slider

		// Set up the categories/brands slider only once after it is opened
		$('body').on('menu-open', maybeInitNavMainProductsSlider);

		//# –– Init megamenu open/close
		checkMegamenuHash();
		$(window).on('popstate', function(){
			checkMegamenuHash();
		});


		//# –– Mobile Menu
		$('.main-nav__small-menu').on('click', function (e) {
			e.preventDefault();
			$('.main-nav').addClass('is-showing-menu-bar');
		});
		$('.main-nav__close-menu-section').on('click', function (e) {
			closeMegamenu(false);
			$('.main-nav').removeClass('is-showing-menu-bar');
		});


		/**
		 *# – Parallax Initialize
		 */

		if ($('div.parallax-image-block').length) {
			cd_init_skrollr();
			//cd_init_twentytwenty();
		}

		// Initialize TwentyTwenty for all pages
		cd_init_twentytwenty();

		/**
		 * Initialize TwentyTwenty
		 *
		 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
		 */
		function cd_init_twentytwenty() {
			// TwentyTwenty
			$(".twentytwenty-container").twentytwenty({
				default_offset_pct: .7932,
			});
		}

		/**
		 * Initialize Skrollr
		 *
		 * mobileCheck is set to a function that returns false to prevent skrollr from hijacking mobile scrolling.
		 *
		 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
		 */
		function cd_init_skrollr() {
			var regexp = /Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i
			var userAgentString = navigator.userAgent ||
								  navigator.vendor ||
								  window.opera;
			var mobile = (regexp).test(userAgentString);

			if ( ! mobile ) {
				skrollr.init({
					smoothScrolling: false,
					forceHeight: false,
					mobileCheck: function() { return false; },
				});
			}
		}

		/**
		 * Initialize Promotion Popup Variables
		 */
		let scrollDistance = 0;
		let defaultDistance = 900;
		let heightCutoff = 1900; // max content height to use default size
		// would just use #content but want to include woocommerce pages. More generic.
		if ( $('.off-canvas-wrap').length && $('.footer').length ) {
			let contentHeight = $('.off-canvas-wrap').outerHeight() - $('.footer').outerHeight();
			if ( contentHeight > heightCutoff ) {
				scrollDistance = defaultDistance;
			} else {
				scrollDistance = contentHeight / 2;
			}

		}
		let newsletterTriggered         = false; // make sure popup is only triggered once
		let headerNewsletterTriggered   = false; // make sure popup is only triggered once

		//! –– ON SCROLL
		$(window).scroll(function(){
			if ( !newsletterTriggered ) {
				newsletterTriggered = promotionPopupScroll(scrollDistance);
			}
			if( !headerNewsletterTriggered ) {
				headerNewsletterTriggered = headerNewsletterScroll();
			}
		});
		
		$('.main-nav__newsletter-signup__close button').on('click', function() {
			closeHeaderNewsletter();	
		});
		
		
		//! –– ON RESIZE
		$(window).on('resize', function(e) {
			// New menu should close megamenu when medium -> large is crossed
			if( $(window).width() > 1024 ) {
				// Not giving me access to this function
				closeMegamenu( true );
			}
		});


		/**
		 * Set Onlick Newsletter Trigger.
		 * Wordpress isn't allowing javascript in an A tag from the customize menu
		 *
		 * For some reason on mobile the link wouldn't trigger the on click.
		 * Element still gets detected just wouldn't trigger it thus set the trigger for the whole p tag
		 */
		if ($('#newsletter-trigger').length) {
			$('.woocommerce-store-notice').on('click', function() {
				triggerNewsletterPopup(0);
				return false;
			});
		}

		initLightboxGallery();
		initImageModal();
		pardotTrackingBanner();
		initNewsletterModal();
	});
})( jQuery );

/**
 * Get Cookie Value
 *
 * @param {string} cname
 * @return {string}
 */
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/**
 * Slides newsletter popup onto the screen
 *
 * @param {int} timeout initial wait time before popup shows
 */
function triggerNewsletterPopup(timeout) {
	console.log('popup triggered');
	let overlay = $('.homepage-newsletter-signup__overlay');
	let newsletter = $('.homepage-newsletter-signup');
	let close = $('.homepage-newsletter-signup__overlay, .homepage-newsletter-signup .close');
	setTimeout(function() {
		overlay.show();
		newsletter.show();
		setTimeout(function() {
			newsletter.addClass('slide-in');
			overlay.addClass('fade-in');
		}, 10);
	}, timeout);
	close.on('click', function() {
		newsletter.addClass('slide-out');
		overlay.addClass('fade-out');
		setTimeout(function() {
			newsletter.removeClass('slide-in slide-out');
			overlay.removeClass('fade-in fade-out');
			overlay.hide();
			newsletter.hide();
		}, 1000);
	});
}

/**
 * Slides promo popup onto the screen
 *
 * @param {int} delay	initial wait time (ms) or scroll distance (px)
 */
function triggerPromoPopup(delay) {
	// List paths only, including a trailing slash. No domains, etc.
	let excludedPaths = [
		"/windscreen-configurator/start/",
		"/video-library/",
	];
	
	if (excludedPaths.includes(window.location.pathname)) {
		return false;
	}

	let promoPopupPresent = false;

	// Set 1ms delay if not set
	if ( !delay ) {
		delay = 1;
	}
	// Trigger promotion popup
	if ($('.beacon-promotion-popup').length && $('body.site-1').length){
		promoPopupPresent = true;
	} else {
		if ( $('.homepage-newsletter-signup').length === 0 ) {
			return;
		}
		// if promotion popup code not present, simply return
	}

	if ( getCookie('promoPopup') === 'seen' ){
		return;
	} else {
		document.cookie = "promoPopup=seen; path=/";
	}

	if ( !promoPopupPresent ) {
		triggerNewsletterPopup();
		return;
	}

	let overlay = $('.beacon-promotion-popup__overlay');
	let promotion = $('.beacon-promotion-popup');
	let close = $('.beacon-promotion-popup__overlay, .beacon-promotion-popup .close');
	setTimeout(function() {
		overlay.show();
		promotion.show();
		setTimeout(function() {
			promotion.addClass('slide-in');
			overlay.addClass('fade-in');
		}, 10);
	}, delay);

	close.on('click', function() {
		promotion.addClass('slide-out');
		overlay.addClass('fade-out');
		setTimeout(function() {
			promotion.removeClass('slide-in slide-out');
			overlay.removeClass('fade-in fade-out');
			overlay.hide();
			promotion.hide();
		}, 1000);
	});
}

/**
 * Trigger promotion popup once scroll distance has been hit
 *
 * @param {int} scrollDistance	Distance (in px) a user scrolls to trigger
 * @return {boolean}	True if promotion is triggered, false otherwise
 */
function promotionPopupScroll( scrollDistance ) {
	let scrollTop = $(window).scrollTop();
	// Trigger popup after 2 seconds if no height present
	if ( scrollDistance === 0 ) {
		triggerPromoPopup(1000);
		return true;
	} else if ( scrollTop > scrollDistance ) {
		triggerPromoPopup();
		return true;
	}
	return false;
}

/**
 * Set a cookie with a number of days offset
 * @param {string} name 
 * @param {string} value 
 * @param {int} daysOffset 
 */
function setExpirationCookie( name, value, daysOffset ) {
	let today       = new Date();
	let expire      = new Date();
	expire.setTime(today.getTime() + 3600000*24*daysOffset);
	document.cookie = name + '=' + value + '; path=/; expires=' + expire.toGMTString();
	return;
}

/**
 * Trigger header newsletter banner once scroll distance has been hit
 *
 * @param {int} scrollDistance	Distance (in px) a user scrolls to trigger
 * 
 * @return {boolean}	True if promotion is triggered, false otherwise
 */
 function headerNewsletterScroll() {
	let windowHeight        = $(window).height();
	let scrollTop           = $(window).scrollTop();
	let $signup             = $('.main-nav__newsletter-signup');
	let $mainNav            = $signup.closest('#main-nav');
	if( !$signup.length ) return true; // stops function from constantly being called
	if( getCookie('headerNewsletter') !== 'seen' ) {
		if( scrollTop > (windowHeight / 4) ) {
			setExpirationCookie( 'headerNewsletter', 'seen', 7 );
			$signup.slideDown({
				start: function () {
					$(this).css('display', 'flex');
				}
			});
		}
		
		if( $signup.hasClass('is-fixed') ) {
			if( scrollTop < $mainNav.height() ) {
				$signup.removeClass('is-fixed');
			}
		} else {
			if( scrollTop >= $signup.offset().top ) {
				$signup.addClass('is-fixed');
			}
		}
	} else {
		return true;
	}
}

/**
 * Close the header newsletter
 */
function closeHeaderNewsletter() {
	$('.main-nav__newsletter-signup').slideUp();	
	setExpirationCookie( 'headerNewsletter', 'seen', 7 );
}

/**
 * Where the magic begins for the lightbox gallery
 */
function initLightboxGallery() {
	let lightbox = '.lightbox-gallery';
	let modalSelector = '.image-modal';
	let thumbsSelector = lightbox + ' .thumbs';

	if ( ! $(modalSelector).length ) return;

	$(thumbsSelector).find('.thumb').each(function() {
		$(this).on('click', function () {
			let slideIndex  = $(this).attr('data-slide-index');
			const $modal    = $( $('.image-modal').get(0) );
			openImageModal( $modal, slideIndex );
		});
	});
}

/**
 * Calculates the necessary dimensions given an image, container, and calculation type.
 * 
 * @param {int}     imgWidth 
 * @param {int}     imgHeight 
 * @param {int}     containerHeight 
 * @param {int}     containerWidth 
 * @param {string}  type            Options for type of image scaling, contain or cover  
 * 
 * @return {Object}                 Contains the new width and height dimensions. Returns false on bad objects
 */
function imgFitDimensions( imgWidth, imgHeight, containerWidth, containerHeight, type ) {
	let imgRatio        = imgWidth / imgHeight;
	let containerRatio  = containerWidth / containerHeight;
	let test            = false;
	let returnObj       = {};

	switch(type) {
		case 'contain':
			test = (imgRatio > containerRatio); // for contain
		break;
		default:
			test = (imgRatio < containerRatio); // default cover
	}

	if( test ) {
		returnObj.width     = containerWidth;
		returnObj.height    = containerWidth / imgRatio;
	} else {
		returnObj.width     = containerHeight * imgRatio;
		returnObj.height    = containerHeight;
	}

	return returnObj;
}

/**
 * Scales and image to a given container
 * 
 * @param {DOM Object}  container  Container object
 * @param {DOM Object}  image      Image object
 * @param {boolean}     contain     True if image should be contained instead of cover
 * 
 * @return {Object} The scale 
 */
function scaleImageContainer( container, image, contain ) {
	let containerHeight = $(container).height();
	let containerWidth  = $(container).width();
	let imageWidth      = image.naturalWidth;
	let imageHeight     = image.naturalHeight;
	let newDimensions   = [];
	if( contain ) {
		newDimensions   = imgFitDimensions(imageWidth, imageHeight, containerWidth, containerHeight, 'contain');
		if( newDimensions.width > imageWidth ) newDimensions.width = imageWidth;
		if( newDimensions.height > imageHeight ) newDimensions.height = imageHeight;
	} else {
		newDimensions   = imgFitDimensions(imageWidth, imageHeight, containerWidth, containerHeight); 
	}
	
	let scaleFactorX    = newDimensions.width / containerWidth;
	let scaleFactorY    = newDimensions.height / containerHeight;

	if( contain && newDimensions.width > containerWidth && newDimensions.height > containerHeight ) {
		scaleFactorX    = 1;
		scaleFactorY    = 1;
	}

	return { x: scaleFactorX, y: scaleFactorY };
}

function initImageModal() {
	const imageModal        = '.image-modal';
	const close             = `${imageModal}__close`;
	const nav               = `${imageModal}__nav`;
	$(document).ready(function() {
		const $imageModal       = $(imageModal);
		if( !$imageModal.length ) return;
		
		$imageModal.appendTo('body');	
		const $close    = $(close);
		const $nav      = $(nav);
		
		// Adjust image sizes on window resize
		$(window).on('resize', function() {
			scaleImageModal( $imageModal );
		});
		
		// Click navigation arrows
		$nav.on('click', function(e) {
			e.preventDefault();
			if( $(this).hasClass('image-modal__nav--next') ) {
				imageModalNavigate( $imageModal, -1, true ); 
			} else {
				imageModalNavigate( $imageModal, -1, false ); 
			}
		});
		
		// Click modal close button
		$close.on('click', function(e) {
			e.preventDefault();
			closeImageModal( $imageModal );
		});
		
		// Close on outside click
		$imageModal.on('click', function(e){
			if( $(e.target).hasClass('image-modal__items') ) {
				closeImageModal( $imageModal );
			}
		});
		
		// Key pressed when modal is open
		$(document).on('keydown', function(e) {
			if( !$imageModal.hasClass('open') ) return;
			switch( e.key ) {
				// Close
				case 'Escape':
					closeImageModal( $imageModal );
					break;
				// Navigate previous
				case 'ArrowLeft':
					imageModalNavigate( $imageModal, -1, false ); 
					break;
				// Navigate next
				case 'ArrowRight':
					imageModalNavigate( $imageModal, -1, true ); 
					break;
			}
		});
	});
}

/**
 * Switch which image is being shown
 * 
 * @param {jQuery} $modal
 * @param {Int} index
 * @param {Bool} forward
 */
function imageModalNavigate( $modal, index = -1, forward = true ) {
	const $items            = $modal.find( '.image-modal__item' );
	const numItems          = $items.length;
	let currentIndex        = 0;
	let $currentItem;
	for( let i = 0; i < numItems; i++ ) {
		if( $($items[i]).hasClass('open') ) {
			currentIndex    = i;
			$currentItem    = $( $items[i] );
			break;
		}
	}
	
	// If someone supplied an index
	let newIndex            = Math.min( numItems - 1, index );
	
	// Move forward/backward
	if( index < 0 ) {
		if( forward ) {
			newIndex = currentIndex + 1 >= numItems ? 0 : currentIndex + 1;
		} else {
			newIndex = currentIndex - 1 < 0 ? numItems - 1 : currentIndex - 1;
		}
	}
	$currentItem.removeClass( 'open' );
	$( $items.get(newIndex) ).addClass( 'open' );
}

/**
 * Open the image modal
 */
function openImageModal( $modal, index = -1 ) {
	// Need something to set the index
	$modal.css( 'display', 'block' );
	scaleImageModal( $modal );
	imageModalNavigate( $modal, index );
	setTimeout(() => {
		$modal.addClass( 'open' );
		$modal.get(0).focus();
	}, 15);
}

/**
 * Close the image modal
 */
function closeImageModal( $modal ) {
	$modal.removeClass( 'open' );
	setTimeout(() => {
		$modal.css( 'display', 'none' );
	}, 350);
}

/**
 * Scales image to the current window dimensions
 */
function scaleImageModal( $modal ) {
	const $container = $modal.find( '.image-modal__items' );
	const $items     = $modal.find( '.image-modal__item' );
	for( let i = 0; i < $items.length; i++ ) {
		const $item             = $( $items.get(i) );
		const $image            = $item.find( '.image-modal__item__source' );
		const $close            = $item.find( '.image-modal__close');
		const scaleFactors      = scaleImageContainer( $container.get(0), $image.get(0), true );
		const { x, y }          = scaleFactors;
		$item.css( 'transform', `translate(-50%, -50%) scale(${x}, ${y})` );
		$close.css( 'transform', `scale(${1/x}, ${1/y})` );
	}
}

/**
 * Checks the original slide height and scales to match current window height.
 * Would have liked for a css only fix but that was proving quite difficult.
 *
 */
 function sliderHeightScale() {
	let modalSelector       = '.gallery-modal';
	let sliderSelector      = modalSelector + ' .slick';
	let screenH             = $(window).height();
	
	// Need to anchor the close button to the image
	// Ugh trickier than I think, contemplating removing slick

	$(sliderSelector + ' .slick-slide > .content').each(function(index) {
		const image     = $(this).find('img').get(0);
		const $close    = $(this).find( '.gallery-modal__close' );
		console.log(image);
		console.log(`Image: ${image.width} x ${image.height}`);
		
		let scaleFactor = 1;
		let slideHeight = $(this).height();

		if ( slideHeight > screenH ) {
			scaleFactor = screenH / slideHeight;
			$(this).css('transform', 'scale(' + scaleFactor + ')');
		}
	});
}

/**
 * Handle some events when home video is enabled
 */
function beaconHomeVideo() {
	const homeVideo         = '.home-video';
	if( !$(homeVideo).length ) return;
	const videoContainer    = `${homeVideo}__video`;
	const videoIcon         = `${videoContainer}__icon`;
	const videoSource       = `${videoContainer}__source`;
	const videoContent      = `${videoContainer}__content`;
	const videoLogo         = `${videoContainer}__logo`;
	const videoBanner       = `${videoContainer}__banner`;
	const $videoContainer   = $(videoContainer);
	const $videoSource      = $(videoSource);
	
	let isRestart           = false;
	
	$videoContainer.on('click', function(event) {
		const $container    = $(this);
		const target        = $(this).find(videoSource).get(0);
		const $pause        = $container.find(`${videoIcon}.pause`);
		const $play         = $container.find(`${videoIcon}.play`)
		const $restart      = $container.find(`${videoIcon}.restart`);
		const isRestart     = $restart.hasClass('is-open');
		const $banner       = $container.find(videoBanner);
		const $logo         = $container.find(videoLogo);
		const $content      = $container.find(videoContent);
		if( !target.paused ) {
			$pause.removeClass('is-open');
			$play.addClass('is-open'); 
			target.pause();
		} else {
			if( !isRestart ) {
				$play.removeClass('is-open');
				$pause.addClass('is-open');
				target.play();
				setTimeout(function() {
					$pause.removeClass('is-open');
				}, 1000);
			} else {
				isRestart = false;
				/*
				$logo.removeClass('is-open');
				$content.removeClass('is-open');
				$banner.removeClass('is-open');
				$container.removeclass('is-hidden');
				setTimeout(function() {
					target.currentTime = 0;
					target.play();
				}, 500);
				*/
			}
		}
	});
	
	$videoSource.on('ended', function(event) {
		const $target       = $(this);
		const $container    = $target.closest(videoContainer);
		const $banner       = $container.find(videoBanner);
		const $logo         = $container.find(videoLogo);
		const $content      = $container.find(videoContent);
		$target.addClass('is-hidden');
		setTimeout(function() {
			$banner.addClass('is-open');
			setTimeout(function() {
				$logo.addClass('is-open');
				$content.addClass('is-open');
			}, 1000);
		}, 350);
		isRestart = true;
	});
}

/**
 * Handle best of seven transition functionality
 */
function beaconBestOfSeven() {
	const bestOfSeven       = '.best-of-seven';
	const $bestOfSeven      = $(bestOfSeven);
	if( !$bestOfSeven.length ) return;
	const bannerCover       = `${bestOfSeven}__banner.cover`;
	const diamond           = `${bestOfSeven}__diamond`;
	const scoreboard        = `${bestOfSeven}__scoreboard`;
	const navigation        = `${scoreboard}__navigation`;
	const topInfo           = `${bestOfSeven}__top-info`;
	const condition         = `${topInfo}__condition`;
	const scores            = `${bestOfSeven}__scores`;
	const score             = `${scores}__score`;
	const headings          = `${bestOfSeven}__headings`;
	const heading           = `${headings}__heading`;
	const end               = `${bestOfSeven}__end`;
	const start             = `${bestOfSeven}__start`;
	const startButton       = `${start}__button`;
	const $navigation       = $(navigation);
	const $startButton      = $(startButton);
	const $bannerCover      = $(bannerCover);
	const $start            = $(start);
	const $scoreBoard       = $(scoreboard);
	const $end              = $(end);
	let stateIndex          = 0;
	let maxState            = 3;
	let autoplayEnabled     = true;
	
	function scoreBoardForward() {
		if( stateIndex === maxState ) {
			autoplayEnabled = false;
			endBestOfSeven();
			return;
		}
		const currentHeading    = `${heading}.is-visible`;
		const nextHeading       = `${heading}[index="${stateIndex + 1}"]`;
		const currentCondition  = `${condition}.is-visible`;
		const nextCondition     = `${condition}[index="${stateIndex + 1}"]`;
		const nextDiamond       = `${diamond} svg rect[index="${stateIndex + 1}"]`;
		
		$(score).each(function() {
			if( stateIndex + 1 === maxState && !$(this).hasClass('winner') ) return;
			let $span       = $(this).find('span');
			let newVal      = parseInt($span.get(0).innerText) + 1;
			$span.get(0).innerText = newVal;
		});
		
		$(currentHeading).removeClass('is-visible');
		$(nextHeading).addClass('is-visible');
		
		$(currentCondition).removeClass('is-visible');
		$(nextCondition).addClass('is-visible');

		$(nextDiamond).attr('class', 'colored');
		stateIndex++;
	}
	
	function scoreBoardBackward() {
		if( stateIndex === 0 ) return;		
		const currentHeading    = `${heading}.is-visible`;
		const nextHeading       = `${heading}[index="${stateIndex - 1}"]`;
		const currentCondition  = `${condition}.is-visible`;
		const nextCondition     = `${condition}[index="${stateIndex - 1}"]`;
		const currentDiamond    = `${diamond} svg rect[index="${stateIndex}"]`;
		
		$(score).each(function() {
			if( stateIndex - 1 === maxState - 1 && !$(this).hasClass('winner') ) return;
			let $span       = $(this).find('span');
			let newVal      = parseInt($span.get(0).innerText) - 1;
			$span.get(0).innerText = newVal;
		});
		
		$(currentHeading).removeClass('is-visible');
		$(nextHeading).addClass('is-visible');
		
		$(currentCondition).removeClass('is-visible');
		$(nextCondition).addClass('is-visible');

		$(currentDiamond).attr('class', '');
		stateIndex--;
	}
	
	function startBestOfSeven() {
		$start.addClass('is-hidden');
		setTimeout(function() {
			$bannerCover.css('display', 'block');
			setTimeout(function() {
				$bannerCover.addClass('is-visible');
			}, 50);
		}, 800);
		setTimeout(function() {
			$scoreBoard.addClass('is-visible');
		}, 1500);
		
		// Wait for all transitioning to finish then autoplay
		setTimeout(function() {
			setInterval(function() {
				if( autoplayEnabled ) {
					scoreBoardForward();
				}
			}, 3000);
		}, 3000);
		
	}
	
	function endBestOfSeven() {
		$bannerCover.removeClass('is-visible');
		
		setTimeout(function() {
			$bannerCover.css('display', 'block');
		}, 1000);
		setTimeout(function() {
			$scoreBoard.removeClass('is-visible');
		}, 150);
		
		setTimeout(function() {
			$end.css('display', 'flex');
			setTimeout(function() {
				$end.addClass('is-visible');
			}, 50);
		}, 850);
	}
	
	// Auto triggered now
	$startButton.on('click', function(event) {
		event.preventDefault();
		startBestOfSeven();
	});
	
	setTimeout(function() {
		startBestOfSeven();
	}, 3500)
	
	$navigation.on('click', function(event) {
		event.preventDefault();
		autoplayEnabled = false;
		const $button = $(this);
		if( $button.hasClass('forward') ) {
			scoreBoardForward();
		} else {
			scoreBoardBackward();
		}
	});
}

/**
 * Pardot cookie banner customizations
 * 
 * This banner is set through a script and it's styles are inline.
 * We could use !important but we want to adjust the text as well, mine as well keep it all JS.
 */
function pardotTrackingBanner() {
	setTimeout(() => {
		if( $('#pi_tracking_opt_in_div').length > 0 ) {
			const $banner   = $('#pi_tracking_opt_in_div');
			const $items	= $banner.find(' > *');
			$banner.html( '<div>This page uses cookies for tracking to enhance your browsing experience. Questions? Visit our <a href="/privacy-policy">privacy policy</a>.</div>' );
			$items.appendTo( $banner );
			$('#pi_tracking_opt_in_yes').text( 'Accept' );
			$('#pi_tracking_opt_in_no').text( 'Decline' );
			$banner.css({
				'top': 'unset',
				'bottom': '0',
				'left': '50%',
				'transform': 'translateX(-50%)',
				'position': 'fixed',
				'padding': '0.5rem',
				'display': 'flex',
				'justify-content': 'center',
				'align-items': 'center',
				'gap': '1rem',
				'opacity': '1',
				'z-index': '99',
			});
		}
	}, 1000);
}

/**
 * Simple function to check for touch screens
 * 
 * @see https://stackoverflow.com/a/4819886
 */
function isTouchDevice() {
	return (('ontouchstart' in window) ||
		(navigator.maxTouchPoints > 0) ||
		(navigator.msMaxTouchPoints > 0));
}

/**
 * Handle functionality for the desktop slide menu 
 */
function desktopSlideMenu() {
	const menu                      = '.main-nav__menu-slide';
	const item                      = `${menu}__item`;
	const childMenu                 = `${menu}__child-menu`;
	const childItem                 = `${childMenu}__item`;
	const childItemTitle            = `${childItem}__title`;
	const children                  = `${childItem}__children`;
	const childItemTitleContent     = `${childItemTitle}__content`;
	
	const $item                     = $( item );
	const $children                 = $( children );
	const $childItemTitle           = $( childItemTitle );
	const $childItemTitleContent    = $( childItemTitleContent );
	const mouseLeaveDelay           = 600;
	
	function openMenu( $menu ) {
		$menu.css('display', 'block');
		$menu.each(function(i, thisMenu) {
			const $thisMenu         = $( thisMenu );
			const windowBottom      = $(window).height() + $(window).scrollTop();
			const menuBottom        = $thisMenu.offset().top + $thisMenu.outerHeight(true);
			const bottomDifference  = windowBottom - menuBottom - 40; // 40px for chat icon
			if( bottomDifference < 0 ) {
				$thisMenu.css( 'transform', `translateY(${bottomDifference}px)` );
			}
		});
		$menu.addClass( 'active' );
	}

	function closeMenu( $menu ) {
		$menu.removeClass( 'active' );
		$menu.find( children ).removeClass( 'active' );
		setTimeout(function() {
			$menu.css( 'transform', '' );
			$menu.css( 'display', 'none' );
		}, 333);
	}
	
	$item.each(function(i, thisItem) {
		let itemLeave   = true;
		const $thisItem = $(thisItem);
		$thisItem.on('mouseenter focus', function(e) {
			itemLeave       = false;
			const $thisMenu = $(this).find(childMenu);
			openMenu( $thisMenu );
		});	
		$thisItem.on('mouseleave blur', function(e) {
			const $relatedTarget    = $(e.relatedTarget).closest(item);
			itemLeave       = true;
			const $thisMenu = $(this).find(childMenu);
			if( $thisMenu.get(0) == $relatedTarget.closest(childMenu).get(0) ) return;
			if( $relatedTarget.find(childMenu).length ) {
				closeMenu( $thisMenu );
			} else {
				setTimeout(function() {
					if( !itemLeave ) return;
					closeMenu( $thisMenu );
				}, mouseLeaveDelay);
			}
		});
	});
	
	$childItemTitle.each(function(i, thisItem) {
		let itemLeave           = true;
		const $theseChildren    = $(thisItem).siblings(children);

		$(thisItem).on('mouseenter', function(e) {
			itemLeave       = false;
			openMenu( $theseChildren );
		});
		$(thisItem).on('mouseleave', function(e) {
			const $relatedTarget    = $(e.relatedTarget).closest(item);
			const $this             = $(this);
			const childrenMatch     = $(e.relatedTarget).closest(children).get(0) == $theseChildren.get(0);
			if( childrenMatch ) return;
			const $thisMenu         = $this.find(childMenu);
			if( $relatedTarget.find(childMenu).length ) {
				closeMenu( $theseChildren );
				closeMenu( $thisMenu );
			} else {
				itemLeave       = true;
				setTimeout(function() {
					if( !itemLeave ) return;
					closeMenu( $theseChildren );
					closeMenu( $thisMenu );
				}, mouseLeaveDelay);
			}
		});
		
	});
	
	$children.each(function(i, thisChildren) {
		let childLeave = true;
		
		$(thisChildren).on('mouseenter', function(e) {
			childLeave = false;
			openMenu( $(this) );
		});
		$(thisChildren).on('mouseleave', function(e) {
			childLeave      = true;
			const $this     = $(this);
			setTimeout(function() {
				if( !childLeave ) return;
				closeMenu( $this );
			}, mouseLeaveDelay);
		});
	});
	
	$childItemTitleContent.each(function(i, thisContent) {
		const $thisContent      = $(thisContent);
		
		$thisContent.on('focus', function(e) {
			const $menu     = $(this).closest(childItemTitle).siblings(children);
			openMenu( $menu );
		});
		$thisContent.on('blur', function(e) {
			let $menu       = $(this).closest( childItemTitle ).siblings( children );
			if( !$menu.get(0) ) {
				$menu   = $(this).closest(children);
			}
			
			let $targetMenu       = $(e.relatedTarget).closest( children );
			
			if( $menu.get(0) == $targetMenu.get(0) ) return;
			
			if( !$targetMenu.get(0) ) {
				// Need to somehow close all the other children menus
				const $topChildren = $(this).closest(`${children}[data-depth="0"]`);
				closeMenu( $topChildren );
			}
			
			// When it flips to the next header option
			if( $(e.relatedTarget).hasClass('main-nav__menu-slide__item') ||
				$(e.relatedTarget).hasClass('main-nav__menu-slide__section-label')
			) {
				closeMenu( $(this).closest(childMenu) );
			}
			
			closeMenu( $menu );
		});
	})
}

/**
 * Replace the old search system with this one. 
 */
function fullSizeSearch() {
	const $searchModal      = $( '.search-modal' );
	const $searchField      = $( '.search-modal .search-field' );
	const $menuSearch       = $( '.main-nav__full-icon.search' );
	const $searchForm       = $( '.search-form');
	const $searchSubmit     = $( `.search-modal .search-submit`);
	
	function open() {
		$searchModal.css( 'display', 'block' );
		setTimeout(function() {
			$searchModal.addClass( 'is-open' );
			$searchField.focus();
		}, 15);
	}
	
	function close() {
		$searchField.blur();
		$searchModal.removeClass( 'is-open' );
		setTimeout(function() {
			$searchModal.css( 'display', 'none' );
		}, 315);
	}

	$menuSearch.on('click', function(e) {
		e.preventDefault();
		if( $searchModal.hasClass( 'is-open' ) ) {
			close();
		} else {
			open();
		}
	});
	
	// Close on outside click
	$searchModal.on('click', function(e) {
		if( !$(e.target).hasClass('search-modal') ) return;
		close();
	});
	
	// Loader on search
	$searchForm.on('submit', function(e) {
		$searchSubmit.addClass( 'loading' );
	});
	
	// When escape is clicked and the modal is open
	$(document).on('keydown', function(e) {
		if( $searchModal.hasClass( 'is-open' ) ) {
			if( e.key === 'Escape' ) close();
		}
	})
}

/**
 * If the slider overlay is present, anchor it to the bottom of the slider.
 */
function beaconSliderOverlayAnchor() {
	const $overlay          = $( '.promo-slider-overlay' );
	const $promoSlider      = $( '.promo-slider' );
	if( !$overlay.length || !$promoSlider.length ) return;
	
	function adjustOverlayTop() {
		const overlayHeight     = $overlay.height() * (5/6);
		const sliderTop         = $promoSlider.height() + $promoSlider.offset().top;
		// Get slider top + height
		$overlay.css( 'top', `${sliderTop - overlayHeight}px` );
		$overlay.addClass( 'active' );
	}
	
	// Need to adjust this on resize
	adjustOverlayTop();
	$(window).resize(function(e) {
		adjustOverlayTop();
	});
}

/**
 * Functionality for the newsletter modal
 */
function initNewsletterModal() {
	const newsletterModal   = '.newsletter-modal';
	const $newsletterModal  = $( newsletterModal );
	if( !$newsletterModal.length ) return;
	const trigger   = `${newsletterModal}__trigger`;
	const close     = `${newsletterModal}__close`;
	const $trigger  = $( trigger );
	const $close    = $( close );
	
	function openModal() {
		console.log($newsletterModal);
		$newsletterModal.css('display', 'block');
		setTimeout(function() {
			$newsletterModal.addClass( 'is-open' );
		}, 15);
	}

	function closeModal() {
		$newsletterModal.removeClass( 'is-open' );
		setTimeout(function() {
			$newsletterModal.css('display', 'none');
		}, 375);
	}
	
	$newsletterModal.on('click', function(e) {
		if( $(e.target).hasClass('newsletter-modal') ) {
			closeModal();
		}
	});
	
	$trigger.on('click', function(e) {
		e.preventDefault();
		openModal();
	});

	$close.on('click', function(e) {
		e.preventDefault();
		closeModal();
	});
	
	$(document).on('keydown', function(e) {
		if( !$newsletterModal.hasClass('is-open') ) return;
		if( e.key === 'Escape' ) closeModal();
	});
}

/**
 * Dynamically filter product categories
 * 
 * Need to get pagination/load more in the mix
 */
function beaconCategoryManipulate() {
	const categoryManipulate        = '.category-manipulate';
	const categoryItem              = `${categoryManipulate}__item`;
	const categoryButton            = `${categoryManipulate}__button`;
	const categoryReveal            = `${categoryManipulate}__reveal`;
	const categorySubcat            = `${categoryManipulate}__subcat`;
	const categorySortOption        = `${categoryManipulate}__sort__option`;
	const categoryClear             = `${categoryManipulate}__clear`;
	
	const productLoop               = '.product-loop';
	const productLoopLoading        = `${productLoop}__loading`;
	const productLoopContainer      = `${productLoop}__container`;
	const productLoopList           = `${productLoop}__list`;

	const loadMore                  = `${categoryManipulate}__load-more`;
	const loadMoreLoading           = `${loadMore}__loading`;
	let scrollLoad                  = false;

	const revealDelay               = 333;
	let revealTimeout;
	
	/**
	 * Get the markup for the product loop
	 * 
	 * @param {Object} data
	 */
	async function getProductLoop( data ) {
		const $productLoop      = $( productLoop );
		const $loadMore         = $( loadMore );
		const $replaceContainer = $productLoop.find( productLoopContainer );
		const $loading          = $productLoop.find( productLoopLoading );
		
		open( $loading, 'flex' );
		
		const response  = await $.ajax({
			type: 'POST',
			url: '/wp-json/bu-rest/v1/product-loop',
			dataType: 'json',
			data: data,
		});

		if( $productLoop.length && response.success ) {
			$( $replaceContainer[0] ).html( response.markup );
		}
		
		if( response.has_more ) {
			$loadMore.attr( 'data-page', 2 );
			open( $loadMore );
		} else {
			close( $loadMore );
		}
		
		close( $loading );
		
		// Update load more
	}
	
	function open( $elem, display = 'block' ) {
		$elem.css( 'display', display );
		setTimeout(function() {
			$elem.addClass( 'open' );
		}, 15);
	}

	function close( $elem ) {
		$elem.removeClass( 'open' );
		setTimeout(function() {
			$elem.css( 'display', 'none' );
		}, 315);
	}
	
	/**
	 * Get the markup for additional products
	 * 
	 * @param {Object} data
	 */
	async function appendProductLoop( data ) {
		const $productLoopList  = $( productLoopList );
		const $loadMore         = $( loadMore );
		
		const response  = await $.ajax({
			type: 'POST',
			url: '/wp-json/bu-rest/v1/product-loop',
			dataType: 'json',
			data: data,
		});
		
		// Instead of replace, need to append
		// Need to increment the button if there are more pages
		// Hide if there aren't more pages
		if( $productLoopList.length && response.success ) {
			$(response.markup).appendTo( $productLoopList[0] );
		}
		
		if( response.has_more ) {
			const paged     = $loadMore.attr( 'data-page' );
			$loadMore.attr( 'data-page', parseInt(paged) + 1 );
			open( $loadMore );
		} else {
			close( $loadMore );
		}
	}
	
	function toggle( $elem ) {
		if( $elem.hasClass('open') ) {
			close( $elem );
		} else {
			open( $elem );
		}
	}
	
	/**
	 * Close if next target isn't inside the reveal content
	 */
	function blurCategoryButton( e, $reveal ) {
		const $related  = $( e.relatedTarget );
		const inReveal  = $related.closest( categoryReveal ).length;
		const isReveal  = $related.hasClass( 'category-manipulate__reveal' );
		if( isReveal || inReveal ) return;
		close( $reveal );
	}
	
	/**
	 * Give some style to the selected manipulate item
	 * 
	 * @param {jQuery} $item 
	 */
	function setClicked( $item ) {
		const $allItems       = $item.closest(categoryReveal).find( categoryItem );
		$allItems.removeClass( 'active' );
		$item.addClass( 'active' );
	}
	
	/**
	 * Filter by subcategory
	 */
	async function clickFilterSubcat(e) {
		e.preventDefault();
		const $this             = $( this );
		setClicked( $this );
		close( $this.closest(categoryReveal) );

		const $container        = $this.closest( categoryManipulate );
		const $clear            = $container.find( categoryClear );
		const subcat            = $this.attr( 'data-subcat' );
		
		$container.attr( 'data-subcat', subcat );
		
		// Somehow need to know what current sorting values are in play
		await getProductLoop({
			category: $container.attr( 'data-cat' ),
			subcat: subcat,
			taxonomy: $container.attr( 'data-taxonomy' ),
			orderby: $container.attr( 'data-orderby' ),
			order: $container.attr( 'data-order' ),
			metakey: $container.attr( 'data-metakey' ),
		});
		if( subcat !== 'all' ) {
			open( $clear, 'flex' );
		}
	}
	
	/**
	 * Sort Category Options
	 */
	async function clickCatSort(e) {
		e.preventDefault();
		const $this             = $( this );
		setClicked( $this );
		close( $this.closest(categoryReveal) );

		const $container        = $this.closest( categoryManipulate );
		const $clear            = $container.find( categoryClear );
		const orderby           = $this.attr( 'data-orderby' );
		const order             = $this.attr( 'data-order' );
		const metakey           = $this.attr( 'data-metakey' );
		
		$container.attr( 'data-orderby', orderby );
		$container.attr( 'data-order', order );
		$container.attr( 'data-metakey', metakey );
		
		await getProductLoop({
			orderby: orderby,
			order: order,
			metakey: metakey,
			category: $container.attr( 'data-cat' ),
			taxonomy: $container.attr( 'data-taxonomy' ),
			subcat: $container.attr( 'data-subcat' ),
		});
		open( $clear, 'flex' );
	}
	
	/**
	 * Clear the filter and sorting options
	 */
	async function clickClear() {
		const $container        = $(this).closest( categoryManipulate );
		const $clear            = $container.find( categoryClear );
		const $allItems         = $container.find(categoryReveal).find( categoryItem );
		
		await getProductLoop({
			category: $container.attr( 'data-cat' ),
			taxonomy: $container.attr( 'data-taxonomy' ),
		});	
		$allItems.removeClass( 'active' );
		$container.attr( 'data-orderby', '' );
		$container.attr( 'data-order', '' );
		$container.attr( 'data-metakey', '' );
		$container.attr( 'data-subcat', '' );
		close( $clear );
	}
	
	/**
	 * Load more items
	 */
	async function clickLoadMore( $this ) {
		const page              = $this.attr( 'data-page' );
		const $loading          = $this.find( loadMoreLoading );
		const $container        = $( categoryManipulate );
		open( $loading, 'flex' );
		
		await appendProductLoop({
			append: true,
			page: page,
			orderby: $container.attr( 'data-orderby' ),
			order: $container.attr( 'data-order' ),
			metakey: $container.attr( 'data-metakey' ),
			category: $container.attr( 'data-cat' ),
			taxonomy: $container.attr( 'data-taxonomy' ),
			subcat: $container.attr( 'data-subcat' ),
		});
		
		close( $loading );
	}
	
	/**
	 * Load more when scroll gets close
	 */
	async function scrollLoadMore(e) {
		if( scrollLoad ) return;
		const $loadMore         = $( loadMore );	
		if( !$loadMore.length || $loadMore.css('display') == 'none' ) return;
		const top               = $loadMore.offset().top;
		const windowHeight      = $(window).height();
		const scroll            = window.scrollY;
		const scrollBottom      = windowHeight + scroll + 300; // little extra lead space
		if( scrollBottom > top ) {
			scrollLoad              = true;
			await clickLoadMore( $loadMore );
			scrollLoad              = false;
		}
	}
	
	$(document).on('ready', function() {
		const isTouch           = isTouchDevice();
		const $categoryButton   = $( categoryButton );
		$categoryButton.on( 'click mouseenter', function(e) { 
			const $reveal = $(this).siblings(categoryReveal);
			if( e.type == 'mouseenter' ) {
				if( !isTouch ) open( $reveal );
			} else {
				toggle( $reveal ) 
			}
		});

		$categoryButton.on( 'blur mouseleave', function(e) { blurCategoryButton(e, $(this).siblings(categoryReveal)) } );
		
		const $categorySubcat   = $( categorySubcat );
		$categorySubcat.on( 'click', clickFilterSubcat );
		
		const $categoryReveal   = $( categoryReveal );
		$categoryButton.on( 'mouseenter', function(e) { open($(this).closest(categoryReveal)) } );
		$categoryReveal.on( 'mouseleave', function(e) { blurCategoryButton(e, $(this).closest(categoryReveal)) } );
		
		const $categorySortOption       = $( categorySortOption );
		$categorySortOption.on( 'click', clickCatSort );
		
		const $cateforyClear    = $( categoryClear );
		$cateforyClear.on( 'click', clickClear );
		
		const $loadMore         = $( loadMore );
		$loadMore.on( 'click', function(e) { clickLoadMore($(this)) } );
		
		$(window).on( 'scroll', scrollLoadMore );
	});
}

/**
 * Take calculation values and update product quantity and price.
 * 
 * For typical square feet.
 * L * W
 *
 * When height is in the mix, this is the calculation used
 * (W * L) + (H * L * 2) + (H * W * 2)
 */
function beaconProductCalculation() {
	const productCalculation        = '.product-calculation';
	const submit                    = `${productCalculation}__submit`;
	const productQuantity           = '.add-cart-quantity input.qty';
	const inputValue                = `${productCalculation}__item__input__value`;
	
	$(document).on('ready', function() {
		const $calculation      = $( productCalculation );
		if( !$calculation.length ) return;
		
		const $submit                   = $( submit );
		const $inputValues              = $( inputValue );
		let variationHasCalculation     = false;
		let variationPrice              = 0;
		let variationSalePrice          = 0;
		let originalPrice               = $('.variations_form .product-price-variable').html();
		
		/**
		 * Update the price based on new quantity or the original price.
		 */
		function updatePrice( $price, original = false ) {
			if( original ) {
				$price.closest('.product-price-variable').html( originalPrice );
			} else {
				const quantity  = $( productQuantity ).val();
				const total     = parseFloat(variationPrice) * parseInt(quantity);
				if( variationPrice == variationSalePrice ) {
					$price.html(`<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>${total.toFixed(2)}</bdi></span>`);
				} else {
					const saleTotal     = parseFloat(variationSalePrice) * parseInt(quantity);
					$price.html(`<del aria-hidden="true"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>${total.toFixed(2)}</bdi></span></del> <ins><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>${saleTotal.toFixed(2)}</bdi></span></ins>`)
				}
			}
			
		}
		
		/**
		 * Calculate the new quantity based on dimensions and calculation type
		 */
		function quantityCalculate( $button ) {
			if( variationPrice === 0 ) return;
			
			const calculationType   = $calculation.attr( 'data-calculation' );
			const $quantity         = $( productQuantity );
			let length              = parseInt( $calculation.find( 'input[name="length"]' ).val() );
			let width               = parseInt( $calculation.find( 'input[name="width"]' ).val() );
			let height              = parseInt( $calculation.find( 'input[name="height"]' ).val() );
			let total               = 1;
			
			switch( calculationType ) {
				case 'sqfeet':
					total   = length * width;	
					break;
				// Yes this is the same but for consistency
				case 'sqfeetwl':
					total   = width * length;
					break;
				case 'customhlw':
					total   = (width * length) + (height * length * 2) + (height * width * 2);
					break;
				case 'sqfeethl':
					total   = height * length;
					break;
			}
			if( isNaN(total) ) {
				return;
			} else {
				$('.single_add_to_cart_button').removeAttr( 'disabled' );
			}
			
			setTimeout(function() {
				$quantity.val( total );
				updatePrice( $button.closest('form').find('span.price') );	
			}, 15);
		}
		
		/**
		 * Calculate button, update quantity and price
		 */
		$submit.on('click', function(e) {
			e.preventDefault();
			if( $(this).hasClass( 'disabled' ) ) return;
			$('.single_add_to_cart_button').removeAttr( 'disabled' );
			quantityCalculate( $(this) );
		});
		
		/**
		 * Update quantity and price when variation changes
		 * 
		 * If variation is hidden, default to original price
		 * 
		 * Need to check the default value
		 */
		$('.single_variation_wrap').on('hide_variation show_variation', function(e, variation) {
			if( variation ) {
				if( variation.disable_cart_calculate ) {
					$('.single_add_to_cart_button').attr( 'disabled', 'disabled' );
				} else {
					$('.single_add_to_cart_button').removeAttr( 'disabled' );
				}
			} else {
				$('.single_add_to_cart_button').removeAttr( 'disabled' );
			}
			const $calculation              = $( productCalculation );
			const variableCalculation       = $calculation.hasClass( 'variation-calculation' );
			if( typeof(variation) === 'undefined' ) {
				variationPrice          = 0;
				variationSalePrice      = 0;
				updatePrice( $(this).closest('form').find('span.price'), true );
				if( variableCalculation ) {
					$calculation.css( 'display', 'none' );
				}
			} else {
				const qtyOverride       = parseInt( variation.qty_override );
				const defaultLength     = parseInt( variation.qty_calculation_default_length );	
				const defaultWidth      = parseInt( variation.qty_calculation_default_width );	
				const defaultHeight     = parseInt( variation.qty_calculation_default_height );	
				$calculation.find( 'input[name="length"].default' ).val( defaultLength );
				$calculation.find( 'input[name="width"].default' ).val( defaultWidth );
				$calculation.find( 'input[name="height"].default' ).val( defaultHeight );
			
				variationPrice          = variation.display_regular_price;
				variationSalePrice      = variation.display_price;
				if( variableCalculation ) {
					variationHasCalculation = variation.has_calculation;
					if( variationHasCalculation ) {
						$calculation.css( 'display', 'flex' );
						// Doesn't seem to help with the custom price
						setTimeout(function() {
							updatePrice( $(document).find('form span.price') );
						}, 10);
					} else {
						$calculation.css( 'display', 'none' );
					}
				}
				if( qtyOverride > 0 ) {
					// Need a little extra time
					setTimeout(function() {
						$( productQuantity ).val( qtyOverride );
						updatePrice( $(document).find('form span.price') );
					}, 10);
				} else {
					quantityCalculate( $submit );
				}
			}
		});
		
		/**
		 * Reset the price when variations are cleared
		 */
		$('.reset_variations').on('click', function(e) {
			updatePrice( $(this).closest('form').find('span.price'), true );
		});
		
		/**
		 * Update price on quantity update.
		 */
		 $(document).on('change bu_quantity_update', productQuantity, function(e) {
			if( variationPrice === 0 ) return;
			updatePrice( $(this).closest('form').find('span.price') );
		 });
		 
		/**
		 * When an input changes, check if all inputs have valid values.
		 * If they do, show the button.
		 */
		$inputValues.on('change', function(e) {
			const $this     = $(this)
			const $submit   = $this.closest(productCalculation).find(submit);
			let valid       = true;
			
			// All the input values need to be a valid number to allow for calculation
			$inputValues.each(function() {
				if( !valid ) return;
				const value = parseInt( $(this).val() );
				if( isNaN(value) ) {
					valid = false;
					return;
				}
			});
			
			if( valid ) {
				$submit.removeClass( 'disabled' );
			} else {
				$submit.addClass( 'disabled' );
			}
		});
	});
}

/**
 * Product page request info form modal
 */
function beaconRequestInfoModal() {
	const requestInfo       = '.request-info';
	const container         = `${requestInfo}__container`;
	const modal             = `${requestInfo}__modal`;
	const modalClose        = `${modal}__close`;
	function open( $modal ) {
		$modal.css( 'display', 'block' );
		setTimeout(function() {
			$modal.addClass( 'open' );
		}, 15)
	}
	
	function close( $modal ) {
		$modal.removeClass( 'open' );
		setTimeout(function() {
			$modal.css( 'display', 'none' );
		}, 315)
	}
	// When button is clicked, open modal
	// Need close when button clicked or keyboard input
	$(document).ready(function() {
		const $modal            = $( modal );
		const $requestInfo      = $( requestInfo );
		const $close            = $( modalClose );
		$requestInfo.on('click', function(e) {
			e.preventDefault();
			let $modal      = $(this).closest( container ).find( modal );
			if( !$modal.length ) {
				$modal  = $( modal );
			}
			console.log($modal);
			open( $modal );
		});
		
		$modal.on('click', function(e) {
			if( $(e.target).hasClass( modal.replace('.', '') ) ) {
				close( $modal );
			}
		});
		
		$close.on('click', function(e) {
			close( $modal );
		});
		
		$(document).on('keydown', function(e) {
			if( $modal.hasClass( 'open' ) ) {
				if( e.key === 'Escape' ) close( $modal );
			}
		});
	});
}

/**
 * Can't believe I'm going to need a whole function for this nonsense.
 * Just to deal with the overflow tabs. What a pain.
 */
function beaconProductDataTabs() {
	const tabs          = '.product-data-tabs__tabs';
	const container     = `${tabs}__container`;
	const overflowIcon  = `${tabs}__overflow-icon`;
	

	
	// When the window reaches the point where the tabs would overflow
	$(document).ready(function() {
		const $tabs                 = $( tabs );
		const $container            = $( container );
		const $overflowIcon         = $( overflowIcon );
		const $overflowIconLeft     = $( `${overflowIcon}.left` );
		const $overflowIconRight    = $( `${overflowIcon}.right` );
		
		if( !$tabs.length ) return;
		
		function windowSizeCheck() {
			const paddingWidth      = 30;
			const windowWidth       = window.innerWidth - paddingWidth;
			const tabsScrollWidth   = $container.get(0).scrollWidth;
			if( windowWidth < tabsScrollWidth ) {
				$overflowIconRight.addClass( 'open' );
			} else {
				$overflowIconRight.removeClass( 'open' );
				$overflowIconLeft.removeClass( 'open' );
			}
		}
		
		// Initial check
		windowSizeCheck();
		
		/**
		 * Hide icon when so much has scrolled
		 */
		$container.on('scroll', function() {
			const scrollPercentage = (this.scrollLeft / (this.scrollWidth-this.clientWidth)) * 100;
			// Need one for other icon 
			if( scrollPercentage < 20 ) {
				$overflowIconLeft.removeClass('open');
			} else {
				$overflowIconLeft.addClass('open');
			}
			if( scrollPercentage > 80 ) {
				$overflowIconRight.removeClass( 'open' );
			} else {
				$overflowIconRight.addClass( 'open' );
			}
		});
		
		/**
		 * Auto scroll when icon is clicked
		 */
		$overflowIcon.on('click', function(e) {
			e.preventDefault();
			const distance          = 500;
			const $icon             = $( this );
			const scrollContainer   = $icon.closest(tabs).find(container).get(0);
			if( $icon.hasClass('left') ) {
				scrollContainer.scrollBy({
					left: -distance,
					behavior: 'smooth'
				  });
			} else {
				scrollContainer.scrollBy({
					left: distance,
					behavior: 'smooth'
				});
			}
			
		});

		/**
		 * On window resize
		 */
		$(window).on('resize', windowSizeCheck);
	});
}

/**
 * Back button to trigger someone clicking back in their browser
 */
function beaconBackButton() {
	const backButton        = '.back-button';
	$(document).ready(function() {
		const $backButton       = $( backButton )
		$backButton.on('click', function(e) {
			e.preventDefault();
			window.history.back()
		});
	});
}

/**
 * Block slider functionality
 */
function beaconBlockSlider() {
	const slider    = '.block-slider';
	const args      = {
		autoplay: true,
		arrows: false,
		dots: true,
		infinite: true,
		swipe: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		dotsClass: 'slick-dots beacon',
	};
	$(document).ready(function() {
		const $slider = $( slider );
		$slider.each(function() {
			$(this).slick( args );
		});
	});
}